.contact-section {
  width: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7db6b;
  background-image: radial-gradient(#fad2504f 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  position: relative;
  padding-top: 1%;
}

.contact-wrapper {
  display: flex;
  margin: auto;
  width: 80vw;
}

.contact-icons {
  display: flex;
}

.icon:hover {
  transform: scale(1.1);

}


.contact-text {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  font-weight: 600;

}


.contact-text h2 {

  font-size: 2.2rem;

}

.contact-text span {
  font-weight: 600;
  text-decoration: underline wavy;

}


.contact-image {
  width: 30vw;
}

.contact {
  display: flex;
  align-items: center;
  margin: 10px;
  line-height: 10px;
  width: fit-content;

}

.icon {
  width: 2.3rem;
  margin-right: 10px;
}


@media only screen and (max-width: 990px) {
  .contact-wrapper {
    flex-direction: column-reverse;
    width: 100%;


  }

  .contact-image {
    width: 100%;

  }

  .contact-text {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }




}