@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Cutive+Mono&family=Lekton:wght@400;700&family=Special+Elite&display=swap');

body {
  margin: 0;
  font-family: 'Segoe UI';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 370px;
  background: #c1f7f2;
  /* background-image: radial-gradient(#0a0a0a4f 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px; */
}

a {
  text-decoration: none;
  color: inherit;
}


/* font-family: 'Courier Prime', monospace;
font-family: 'Cutive Mono', monospace;
font-family: 'Lekton', sans-serif;
font-family: 'Special Elite', cursive; */



/* font-family: 'Baloo Bhai 2', cursive;
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Roboto Mono', monospace; */