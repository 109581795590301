/* font-family: 'Baloo Bhai 2', cursive;
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Roboto Mono', monospace; */

.skills-section {
  width: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #c1f7f2;
  /* background-image: radial-gradient(#fad250 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px; */
  position: relative;
}

.skills-outer-container {
  width: 70%;
  display: flex;
  align-items: center;
}

.skills-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  height: 100%;
  width: 40%;
}

.skill-ul {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;

}

.skill-ul li {
  margin: 1% 0.7%;
  background-color: #f7db6b;
  color: black;
  font-weight: 700;
  padding: 0.3vw 0.7vw;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.39);
  border-radius: 5px;

}


.skills-about-wrapper {
  height: 50vh;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  position: relative;

  /* -webkit-clip-path: polygon(35px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 35px), calc(100% - 35px) 100%, 35px 100%, 0% calc(100% - 35px), 0% 35px);
  clip-path: polygon(35px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 35px), calc(100% - 35px) 100%, 35px 100%, 0% calc(100% - 35px), 0% 35px); */
}




.skills-image {
  border-radius: 50%;
  width: 90%;
}

.skills-about p {
  margin: 2% 0;
}


.skills-title {
  width: 70%;
}

.my-skills-title {
  font-size: 2.5rem;
  font-weight: 900;
  text-decoration: underline wavy;
}



.skill-tech-title {
  font-size: 2.2rem;
  font-weight: 800;
  width: fit-content;
  margin: 2vh 0;
}



.skill-li {
  position: relative;
  width: fit-content;

}

.skill-li:hover .tooltip {
  visibility: visible;
  z-index: 2;
}

.tooltip {
  visibility: hidden;
  width: fit-content;
  background-color: white;
  color: black;
  border: black solid 1px;
  text-align: center;
  border-radius: 6px;
  padding: 5%;
  font-size: 0.7rem;
  /* Position the tooltip */
  position: absolute;

  top: 100%;
  left: 50%;
  /* margin-left: -60px; */
}

.skills-about span {
  color: red;
}

@media only screen and (max-width: 990px) {

  .skills-outer-container {
    flex-direction: column;
    margin: 2% 0;
  }

  .skills-outer-container div {
    margin: 2% 0;
  }

  .skills-wrapper {
    width: 100%;
  }

  .skills-about-wrapper {
    width: 100%;
    height: fit-content;
  }

  .skills-section {
    height: auto;

  }

  .skill-tech-title {
    border: none;

  }


}