.navbar-wrapper {
  display: flex;
  justify-content: center;
  font-weight: 900;
  width: 100%;
  padding: 10px 0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
  box-shadow: 0px 16px 15px -3px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 4rem;
  margin-right: 2%;
  cursor: pointer;
}

.navbar-logo-container {
  width: 30%;
}


.navbar-options-container {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.navbar-options {
  margin-left: 3vw;

}



.resume {
  border: 2px solid red;
  padding: 0.5vw;
}



.underlined {
  color: #18272F;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.4rem;
}

.underlined::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #18272F;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.underlined:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}


@media only screen and (max-width: 550px) {
  .navbar-options:nth-child(-n + 4) {
    display: none;
  }

  .logo {
    width: 3rem;
    margin-right: 2%;
  }


}