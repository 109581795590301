.aboutme-section {
  width: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #c1f7f2;
  background-image: radial-gradient(#fad2504f 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  position: relative;
  padding-top: 5%;
}

.aboutme-wrapper {
  display: flex;
  margin: auto;
  width: 80vw;
}



.aboutme-text {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;

}

.aboutme-text span {
  font-weight: 600;
  text-decoration: underline;

}


.aboutme-image {
  width: 30vw;
}

@media only screen and (max-width: 990px) {
  .aboutme-wrapper {
    flex-direction: column-reverse;
    width: 100%;


  }

  .aboutme-image {
    width: 100%;

  }

  .aboutme-text {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }




}