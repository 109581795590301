.project-section {
  width: 99vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7db6b;
  padding-top: 5%;
}

.project-container {
  margin-top: 6vh;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 3%;
}



.project-info-container {
  display: flex;
  justify-content: space-around;
}

.project-name {
  font-size: 2.2rem;
  font-weight: 800;
  width: fit-content;
  margin-bottom: 2vh;
}



.project-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  padding: 1vw 0;
}


.role-ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 20px;
}

.role-ul li {
  color: black;
  padding: 0.3vw 0.7vw;
  font-size: 1.1rem;
  font-weight: 600;
}

.role-ul span {
  color: red;
  font-weight: 600;
  text-decoration: underline;
}

.project-image {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 19px 6px -3px rgba(0, 0, 0, 0.6);
}

.project-image-container {

  border-radius: 20px;
  margin-bottom: 2%;
}

.project-role {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;

}

.skill-ul-project {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
}

.skill-ul-project li {
  margin: 1% 0.7%;
  background-color: black;
  color: #fffffffa;
  font-weight: 700;
  padding: 0.3vw 0.7vw;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.39);
}

.project-btn {
  border: 3px solid black;
  padding: 0.5vw;
  cursor: pointer;
  background-color: black;
  color: white;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.39);
  transition: 500ms;
}

.project-btn:hover {
  transform: scale(1.1);
}

.project-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.project-description,
.project-btn {
  font-size: 1.1rem;
  font-weight: 600;
}

.project-description {
  margin: 3% 0;

}


@media only screen and (max-width: 990px) {


  .project-info-container {
    flex-direction: column;
  }

  .project-info {
    width: 100%;
  }


}