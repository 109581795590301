.introduction-section {
  background-color: #f5d772;
  background-color: #cdeb84;
  background-color: #f7db6b;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  /* width: 80%;
  margin: auto; */
}

.introduction-wrapper {
  display: flex;
  font-weight: 900;
  margin: auto;
  width: 80vw;

}

.introduction-text p {
  margin: 3% auto;
  width: 90%;

}

.introduction-text {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.line1 span {
  color: red;
}

.introduction-line {
  margin: 1.5%;
}

.line1 {
  font-size: 3rem;
}

.line2 {
  font-size: 2.3rem;
}

.line3 {
  font-size: 1.8rem;
}

.introduction-image {
  width: 40rem;
}

@media only screen and (max-width: 990px) {
  .introduction-wrapper {
    flex-direction: column-reverse;
    width: 100%;


  }

  .introduction-image {
    width: 100%;

  }

  .introduction-text {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .line1 {
    font-size: 6vw;
  }

  .line2 {
    font-size: 4.5vw;
  }

  .line3 {
    font-size: 4.5vw;
  }

  .line1 span:nth-child(n+18) {
    color: red;
    font-size: 5vw;
  }


}